import styled from 'styled-components'
import bg from '../../assets/images/baia.jpg'

export const Container = styled.div`
  background: url(${bg}) no-repeat 100% 100%;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(21, 143, 207, 0.3);
  height: 400px;

  padding: 30px 160px;

  h1 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 14px;
    }
  }

  h3 {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    color: #fff;
    width: 600px;
    font-size: 16px;
    font-weight: 100;
    line-height: 1.5;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (max-width: 786px) {
    padding: 10px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1,
    h3,
    p {
      text-align: center;
    }

    h1 {
      color: #fff;
      font-size: 18px;
    }

    h3 {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 1.1;
      font-weight: 600;
      color: #fff;
    }

    p {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
`
