import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { LayoutSingle } from '../../../components/LayoutSingle';
import api from '../../../services/api'




function SingleProject() {

  const location = useLocation();
  const history = useHistory();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const [, , id] = location.pathname.split("/");
    api.get(`/projects`)
      .then(res => {
        setProject(res.data.find(p => p.id === Number(id)) || {});
        setLoading(false)
      })
  }, []);

  return (
    <>
      <LayoutSingle project={project} isLoading={loading} />
    </>
  )
}

export default SingleProject