import styled from 'styled-components';

export const Wrapper = styled.div`
  background: linear-gradient(#fff, #0b7bc0, #000);
  height: 800px;
`;

export const Container = styled.div`
  background: linear-gradient(#fff, #0b7bc0);

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const ContactInfo = styled.div`
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
`;
export const HeaderInfo = styled.div`
  text-align: center;

  span {
    text-transform: uppercase;
    font-size: 14px;
    color: #0b7bc0;
    font-weight: bold;
  }
`;
export const ContactsNumbers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
export const ContactNumber = styled.div`
  margin-bottom: 15px;
`;
export const Socials = styled.div``;
export const SocialMedias = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
export const SocialItem = styled.div`
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: #fff;
  }
`;
export const Email = styled.div``;
export const EmailAddresses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
export const Address = styled.div`
  a {
    text-decoration: none;
    color: #fff;
  }
`;
export const GeoLocation = styled.div`
  iframe {
    height: 800px;
  }
`;
