import React from "react";

import {
  Container,
  InfoManagment,
  Wrapper,
  ImageWrapper,
  Column,
} from "./styles";

import foto1 from "../../assets/images/property-manager.jpg";
import foto2 from "../../assets/images/property-manager1.jpg";
import foto3 from "../../assets/images/property-manager2.jpg";

function SectionManagement() {
  return (
    <Wrapper id="property-manager">
      <Container>
        <InfoManagment>
          <h2>SERVIÇOS QUE OFERECEMOS</h2>
          <p>
            A experiência aliada às fortes valências permite à nossa equipa
            gerir actualmente imóveis de 6 empresas em Angola nos segmentos de
            retail (shopping center e galerias comerciais), escritórios,
            complexos logístico/industriais e residencial.
          </p>
          <h3>GESTÃO DE IMÓVEIS</h3>
          <ul>
            <li>
              <span>✓</span> Gestão de contratos de arrendamento
            </li>
            <li>
              <span>✓</span> Administração de arrendamentos
            </li>
            <li>
              <span>✓</span> Coleta de renda mensal e extractos
            </li>
            <li>
              <span>✓</span> Mapa de rendas
            </li>
            <li>
              <span>✓</span> Gerenciamento e relatórios de serviços públicos
            </li>
            <li>
              <span>✓</span> Revisão e renovação de arrendamentos{" "}
            </li>
            <li>
              <span>✓</span> Leitura de medidores eléctricos / água, e o
              faturamento relevante
            </li>
            <li>
              <span>✓</span> Orçamento de receitas e despesas
            </li>
            <li>
              <span>✓</span> Contas de receitas e despesas
            </li>
          </ul>
          <h3>GESTÃO DE INSTALAÇÕES </h3>
          <ul>
            <li>
              <span>✓</span> Gestão de edifícios/Condomínios{" "}
            </li>
            <li>
              <span>✓</span> Gestão de serviços de utilidade pública
            </li>
            <li>
              <span>✓</span> Gestão de manutenção preventiva{" "}
            </li>
            <li>
              <span>✓</span> Avaliação de risco e redução de risco para
              proprietários e gerentes
            </li>
            <li>
              <span>✓</span> Procedimentos de emergência{" "}
            </li>
            <li>
              <span>✓</span> Gestão e procedimentos de licitação para
              prestadores de serviços
            </li>
          </ul>
        </InfoManagment>
        <ImageWrapper>
          <Column>
            <img src={foto1} />
            <img src={foto2} />
            <img src={foto3} />
          </Column>
        </ImageWrapper>
      </Container>
    </Wrapper>
  );
}

export default SectionManagement;
