import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import docPdf from "../../../assets/images/news/post2.pdf";
import img from "../../../assets/images/bna.jpeg";

import { Container, Main, Post } from "./styles";
import { useHistory } from "react-router-dom";

function SingleNew4() {
  const history = useHistory();

  const { location } = history;

  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              {location && location.search.startsWith("?list=true") ? (
                <Link to="/news">
                  <FaArrowLeft /> Voltar
                </Link>
              ) : (
                <Link to="/#news">
                  <FaArrowLeft /> Voltar
                </Link>
              )}
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>
                <header>
                  <img src={img} alt="Post 1" className="h-7" />
                </header>
                <section>
                  <h2>
                    INVESTIDORES ESTRAGEIROS PODEM REPATRIAR CAPITAIS SEM
                    AUTORIZAÇAO DO BNA
                  </h2>
                  <p>
                    Os empresários estrangeiros que decidem investir em Angola
                    têm agora a vida mais facilitada, com a entrada em vigor de
                    uma medida que permite que os estrangeiros invistam em
                    Angola sem a necessidade de obter qualquer aprovação ou
                    licenciamento junto do banco central, e também para os
                    mesmos realizarem o repatriamento dos seus capitais para os
                    respectivos países.
                  </p>

                  <p>
                    <strong>Fonte: Correio Kianda</strong>
                  </p>

                  <br />

                  <a
                    href="https://correiokianda.info/investidores-estrangeiros-podem-repatriar-seus-capitais-sem-autorizacao-do-bna/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver notícia completa
                  </a>
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default SingleNew4;
