import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import img1 from "../../../assets/images/news/maria.png";

import { Container, Main, Post } from "./styles";

function SingleNew3() {
  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              <Link to="/news">
                <FaArrowLeft /> Voltar
              </Link>
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>

                <section>
                  <div className="cover">
                    <img src={img1} />
                  </div>
                  <div className="content">
                    <h2>MELHOR COLABORADORA DO ANO 2021</h2>

                    <p>
                      <strong>Dra. Maria Rocha</strong> eleita como melhor
                      colaboradora do ano de 2021 na Skyland.
                    </p>

                    <p>
                      Valorizamos a excelência na industria imobiliária, portanto
                      não poderiamos deixar de reconhecer aqueles que fazem o
                      nosso trabalho possível com empenho e dedicação.
                    </p>

                    <p>
                      <strong>Fonte: Skyland</strong>
                    </p>

                    <br />
                  </div>
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default SingleNew3;
