import React from 'react'

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
} from './styles'

function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='about' onClick={toggle}>
            Sobre Nós
          </SidebarLink>
          <SidebarLink to='projects' onClick={toggle}>
            Portfolio
          </SidebarLink>
          <SidebarLink to='property-maneger' onClick={toggle}>
            Gestão de Imoveis
          </SidebarLink>
          <SidebarLink to='events' onClick={toggle}>
            Notícias
          </SidebarLink>
          <SidebarLink to='contacts' onClick={toggle}>
            Contactos
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
