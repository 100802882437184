import styled from "styled-components";

export const Container = styled.div`
  width: 250px;
  background: #1487c9;
  position: absolute;
  bottom: 300px;
  right: 0;
  z-index: 99;
  padding: 10px;

  h1 {
    font-size: 16px;
    color: #fff;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    margin-top: 10px;
    padding-top: 10px;
    color: #fff;

    .contact {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 125px;
    position: fixed;
    bottom: 150px;
    right: 0;
    z-index: 99;
    padding: 10px;

    h1 {
      font-size: 8px;
    }

    .contacts {
      .contact {
        font-size: 8px;
        margin-bottom: 5px;
      }
    }
  }
`;
