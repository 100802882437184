import React from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

import { Container, Main } from "./styles";

export function LayoutBase({ children }) {

  return (
    <>
      <div id="project">
        <Container>
          <header>
            <div className="back">
              <Link to="/#projects">
                <FaArrowLeft /> Voltar
              </Link>
            </div>

            <span>Portfolio de Imóveis</span>
          </header>
        </Container>

        <Main>
          <Container>
            {children}
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  )
}