import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import img1 from "../../../assets/images/galardao.jpg";

import { Container, Main, Post } from "./styles";

function SingleNew5() {
  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              <Link to="/news">
                <FaArrowLeft /> Voltar
              </Link>
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>

                <section>
                  <div className="banner">
                    <img src={img1} />
                  </div>
                  <h2>GALARDÃO DE EXCELENCIS SKYLAND</h2>

                  <p>
                    <strong>Rajeesh cotarth</strong> premiado com o galardao de excelencis Skyland.
                  </p>

                  <p>
                    Valorizamos a excelência na industria imobiliária, portanto
                    não poderiamos deixar de reconhecer aqueles que fazem o
                    nosso trabalho possível com empenho e dedicação.
                  </p>

                  <p>
                    <strong>Fonte: Skyland</strong>
                  </p>

                  <br />
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default SingleNew5;
