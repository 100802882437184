import React from 'react'

import { Container } from './styles'

function AboutUs() {
  return (
    <Container>
      <h1>Sobre Nós</h1>
      <h3>Conheça o nosso negócio</h3>
      <p>
        Empresa do Webcor Group que se dedica à gestão e promoção imobiliária.
      </p>
      <br />
      <p>
        Actualmente temos um portfolio de activos imobiliários de
        aproximadamente 100 imóveis, que ascende a 260M USD, localizados de
        norte a sul de Angola, Moçambique e República Democrática do Congo. A
        nossa carteira de imóveis é transversal aos diversos sectores
        (industrial e logístico, comércio, escritórios e residencial), com
        excelente localizações a valores competitivos. Com mais de 20 anos de
        experiência em imobiliário, a nossa equipa oferece um forte conhecimento
        do mercado angolano, o que nos torna o seu parceiro ideal.
      </p>
    </Container>
  )
}

export default AboutUs
