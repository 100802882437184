import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import OportunityBusiness from "../../components/OportunityBusiness";
import Portfolio from "../../components/sections/Portfolio";
import Sidebar from "../../components/Sidebar";
import Contact from "../Contact";
import { animateScroll as scroll } from "react-scroll";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slides from "../../components/Slides";
import AboutUs from "../../components/AboutUs";
import SectionManagement from "../../components/SectionManagement";
import SectionNews from "../../components/SectionNews";

function Home() {
  const [isOpen, setIsOpen] = useState("");

  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    console.log(location.hash);
    if (location.hash.includes("#projects")) {
      scroll.scrollTo(850);
    }

    if (location.hash.includes("#news")) {
      scroll.scrollTo(2390);
    }
  }, [location]);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Slides />
      <AboutUs />
      <Portfolio />
      <SectionManagement />
      <SectionNews />
      <OportunityBusiness />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
