import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import docPdf from "../../../assets/images/news/post2.pdf";
import img from "../../../assets/images/news/post2.jpg";

import { Container, Main, Post } from "./styles";
import { useHistory } from "react-router-dom";

function SingleNew2() {
  const history = useHistory();

  const { location } = history;

  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              {location && location.search.startsWith("?list=true") ? (
                <Link to="/news">
                  <FaArrowLeft /> Voltar
                </Link>
              ) : (
                <Link to="/#news">
                  <FaArrowLeft /> Voltar
                </Link>
              )}
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>
                <header>
                  <img src={img} alt="Post 1" className="h-7" />
                </header>
                <section>
                  <h2>RELÁTORIO IMOBILIÁRIO 2020.</h2>
                  <p>
                    Relatório criado pela Zenki Real Estate com finalidade de
                    mostrar a realidade o mercado imobiliário angolano em 2020.
                    Descarregue o documento em PDF com todo conteúdo.
                  </p>

                  <p>
                    <strong>Fonte: Zenki Real Estate</strong>
                  </p>

                  <br />

                  <a href={docPdf} target="_blank" rel="noopener noreferrer">
                    Ver notícia completa
                  </a>
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default SingleNew2;
