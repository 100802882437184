import React from "react";
import { Link } from "react-router-dom";
import pic from "../../assets/images/torre-ambient.jpg";
import pic2 from "../../assets/images/news/post2-1.JPG";
import pic3 from "../../assets/images/news/post2-1.JPG";
import pic4 from "../../assets/images/news/post2.jpg";
import premioImg from "../../assets/images/premio-2022.jpg";

import { Wrapper, Container, InfoManagment, ImageWrapper } from "./styles";

function SectionNews() {
  return (
    <Wrapper id="events">
      <Container>
        <InfoManagment>
          <h1 className="white">Notícias</h1>
          <h2>Fique atento as nossas notícias</h2>
          <p>Segue de perto todas as nossas inovações, projectos e produtos</p>
          <div className="btn-center">
            <Link to="/news">Saiba mais</Link>
          </div>
          <div className="btn-desktop">
            <Link to="/news">Saiba mais</Link>
          </div>
        </InfoManagment>
        <ImageWrapper>
          <div className="news">
            <div className="card">
              <div className="card-cover">
                <img src={pic} alt="Cover" />
              </div>
              <div className="card-info">
                <h1>Mercado Imobiliário cai mais de 40% este ano.</h1>
                <h2>
                  Crise: Pandemia colocou o sector imobilário em coma profundo,
                  considera a Associação dos Profissionais Imobiliários de
                  Angola. Procura caiu drasticamente. Há empresas paralisadas.
                </h2>
                <Link
                  to="/news/1"
                  className="btn"
                  style={{ marginTop: "20px" }}
                >
                  Saiba Mais
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card-cover">
                <img src={pic4} alt="Cover" />
              </div>
              <div className="card-info">
                <h1>Relátorio Imobiliário 2020.</h1>
                <br />
                <h2>
                  Relatório criado pela Zenki Real Estate com finalidade de
                  mostrar a realidade o mercado imobiliário angolano em 2020.
                  Descarregue o documento em PDF com todo conteúdo.
                </h2>
                <br />
                <Link
                  to="/news/2"
                  className="btn"
                  style={{ marginTop: "40px" }}
                >
                  Saiba Mais
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card-cover">
                <img src={premioImg} alt="Cover" />
              </div>
              <div className="card-info">
                <h1>Melhor Colaboradora do Ano 2021</h1>
                <br />
                <h2>
                  Valorizamos a excelência na industria imobiliária, portanto
                  não poderiamos deixar de reconhecer aqueles que fazem o nosso
                  trabalho possível com empenho e dedicação.
                </h2>
                <br />
                <br />
                <br />
                <Link
                  to="/news/3"
                  className="btn"
                  style={{ marginTop: "20px" }}
                >
                  Saiba Mais
                </Link>
              </div>
            </div>
          </div>
        </ImageWrapper>
      </Container>
    </Wrapper>
  );
}

export default SectionNews;
