export const projects = [
  {
    id: 1,
    location: 'Luanda',
    type: 'Venda e Arrendamento',
    title: 'Edifício one Metropolis',
    hint: 'Arquitectura moderna e inovativa',
    description: `O Edifício One Metropolis é a representação da arquitectura moderna e da inovação. Este empreendimento está localizado em Talatona, uma das zonas mais privilegiadas de Luanda. São dois edifícios que partilham de um espaço em comum, mas contêm funcionalidades completamente diferentes e características únicas. Um dos edifícios tem a estrutura mais alta de Luanda e tem como funcionalidade escritórios. O outro edifício é composto por estabelecimentos comerciais, desde lojas a restaurantes.`,
    cover: 'f4969be0ff1da18ff62a97145474c359-f1.jpg',
    fotos: [
      { id: 1, url: '/images/cca612319b018464f7668c5c93fc3e70-f4.jpg' },
      { id: 2, url: '/images/f4969be0ff1da18ff62a97145474c359-f1.jpg' },
      { id: 3, url: '/images/bcdc013f5c360ee9967cb997b5a1cf7d-bg-3.jpg' }
    ],
    others: []
  },
  {
    id: 2,
    location: 'Luanda',
    type: 'Arrendamento',
    title: 'Complexo InterMarket Luanda',
    hint: `Galeria comercial, praça de restuaração e escritórios. Áreas desde 22 m<sup>2</sup>`,
    description: `Localizado numa das zonas mais prime da cidade de Luanda, o Complexo Intermarket Luanda oferece espaços comerciais e de restauração flexíveis para instalar o seu negócio. lojas de 22 m<sup>2</sup> a 150 m<sup>2</sup>. Adicionalmente existe disponível espaço de escritórios de 270 m<sup>2</sup>.`,
    cover: '3fa90a5d98b1c49c2e6ef04ff23c7328-Text.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/1764122b70e1cc8d5111f7151883ba8e-IMG.jpg' },
      { id: 2, url: '/images/fd944b8a033ed59005965e6121ef10e7-restauracao.jpg' },
      { id: 3, url: '/images/3fa90a5d98b1c49c2e6ef04ff23c7328-Text.jpg' },
      { id: 4, url: '/images/55ee27bb5c295db9d3fcdffcd165bbb9-E9D473A9-708B-46A0-937A-027B01FEC63F.JPG' },
    ],
    others: [
      {
        id: 1,
        title: 'Lojas',
        description: 'Lojas de até 25 m<sup>2</sup> dentro da galeria comercial.',
        fotos: [
          {
            id: 1,
            url: '/images/lojas/foto1.jpg'
          },
          {
            id: 2,
            url: '/images/lojas/foto2.jpg'
          }
        ]
      },
      {
        id: 2,
        title: 'Restauração',
        description: `Espaços para restauração de até 200 m<sup>2</sup>.`,
        fotos: [
          {
            id: 1,
            url: '/images/restauracao/foto1.jpg'
          },
          {
            id: 2,
            url: '/images/restauracao/foto2.jpg'
          }
        ]
      },
      {
        id: 3,
        title: 'Escritórios',
        description: 'Escritório de 150 m<sup>2</sup> em open space e ainda 4 gabinetes.',
        fotos: [
          {
            id: 1,
            url: '/images/escritorios/foto1.jpg'
          },
          {
            id: 2,
            url: '/images/escritorios/foto2.jpg'
          }
        ]
      }
    ]
  },
  {
    id: 3,
    location: 'Luanda',
    type: 'Arrendamento',
    title: 'Complexo Industrial Cimianto',
    hint: 'Armazéns destinados a comércio, serviços e indústria.',
    description: `Complexo de armazéns destinados a comércio, serviços e indústria, com 86.000m<sup>2</sup> de área que inclui o estacionamento exterior e zona de manobra para camiões. O complexo encontra-se muito bem localizado em zona industrial próximo do centro de Luanda, junto a estrada. O espaço dispõe ainda de 10 vivendas V1 disponíveis para arrendamento.`,
    cover: 'a7963c6ba413aef6ae483d37576e7aa5-IMG_3290.jpg',
    isValueMart: false,
    fotos: [
      {
        id: 1,
        url: '/images/466e9daee9530afbbc021ec587716bc9-IMG_0325.jpg'
      },
      {
        id: 2,
        url: '/images/b1245c406fd8285b00c8068b5ef9f872-IMG_0321.jpg'
      },
      {
        id: 3,
        url: '/images/58fbd150abb7edd929876c887bcf2f87-IMG_3290.jpg'
      },
      {
        id: 4,
        url: '/images/e85d2b36e7d073542c1ae0763bada8f7-IMG_3283.JPG'
      },
      {
        id: 5,
        url: '/images/d0387fb9cc45db015b5eaef8bcb3fd67-IMG_3920.JPG'
      },
      {
        id: 6,
        url: '/images/9014b88bf38720d00508f96b87a3f419-IMG_3869.JPG'
      },
      {
        id: 7,
        url: '/images/25a3a11265f8ad80d8e59ec3f450d9b0-IMG_3873.JPG'
      },
    ],
    others: []
  },
  {
    id: 4,
    location: 'Huíla',
    type: 'Venda e Arrendamento',
    title: 'Escritório Lubango',
    hint: `Imóvel remodelado com 60 m<sup>2</sup> no centro do Lubango.`,
    description: `Apartamento usado para escritorio no centro da cidade de Lubano com 60 m<sup>2</sup>`,
    cover: 'aea406bd3e7503fcecbbb299a31bafb8-IMG_1436.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/05a33ba18285c822d453b0e4b7e11ecd-IMG_1436.jpg' }
    ],
    others: []
  },
  {
    id: 5,
    location: 'Luanda',
    type: 'Venda e Arrendamento',
    title: '11 de Novembro/ Sapu',
    hint: `Terreno com 18.800 m<sup>2</sup> localizado na Estrada Sapu – frente de estrada.`,
    description: `O terreno encontra-se murado e goza de uma excelente localização para desenvolver o seu projecto industrial ou comercial. Disponível para venda ou arrendamento.`,
    cover: 'f5232cea3f7def4ce66a5b523a823f07-IMG_1436.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/6a1cd05d7cdc1b8c7fb0c8b2f8dfd18d-IMG_1483.jpg' },
      { id: 2, url: '/images/9e1b488b2b70114845bf7adf8775332c-20190620_091457.jpg' },
    ],
    others: []
  },
  {
    id: 6,
    location: 'Cabinda',
    type: 'Arrendamento',
    title: 'Armazém Luvassa',
    hint: 'Armazém com 700 m<sup>2</sup> localizado no bairro de Luvassa',
    description: `Inserido num terreno com área total de 2.512 m<sup>2</sup> de terreno, o armazém é uma excelente opção para armazenamento/ logistica dado ficar num bairro nas proximidades do aeroporto.`,
    cover: 'b598bfc56ccc235ef1bfa70525ab6363-cover.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/be713e2fd9d3d336292c6078c84ded4c-IMG_1370.jpg' },
      { id: 2, url: '/images/877e1189df2e18c8968c86ed3ede22dd-IMG_1372.jpg' },
    ],
    others: []
  },
  {
    id: 7,
    location: 'Luanda',
    type: 'Arrendamento',
    title: 'Complexo Industrial Panga Panga',
    hint: `Complexo de armazéns destinado a comércio e indústria, com áreas disponíveis a partir de 830 m<sup>2</sup>.`,
    description: `Com uma área total coberta de 25.200 m<sup>2</sup> destinados a comércio, logística ou indústria. O Complexo Panga Panga oferece armazéns com áreas entre os 830 e os 15.000 m<sup>. Para além disso possuí uma loja com 1.727 m<sup>. Disponível para arrendamento.`,
    cover: '3bf5f0bfb6770bba630d60f290f3c819-IMG_0802.JPG',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/095d3ba4bb7c9fd0420ed1e0da7ac1f1-IMG_0802.JPG' },
      { id: 2, url: '/images/2e36b22cf25c7a7ca207207496615870-IMG_0803.JPG' },
      { id: 3, url: '/images/51381c24cdf513462f1037f3345ba6cd-IMG_0804.JPG' },
    ],
    others: []
  },
  {
    id: 8,
    location: 'Cabinda',
    type: 'Arrendamento',
    title: 'Cabassango',
    hint: `Terreno com 964 m<sup>2</sup> localizado na via principal junto ao Mercado do Cabassango`,
    description: `Terreno totalmente murado apresenta-se como uma excelente oportunidade para desenvolvimento de comércio, dada a sua localização e proximidade com o Mercado do Cabassango. Disponível para arrendamento ou venda`,
    cover: 'b52b0c7b765a433766557be162c76f3c-cover.jpg',
    isValueMart: true,
    fotos: [
      { id: 1, url: '/images/dfac6c00270b39e718cbfd4c9cf97f8d-IMG_1365.jpg' },
      { id: 2, url: '/images/ccc8b8644e74854e88095471f08cc580-IMG_1365.JPG' },
      { id: 3, url: '/images/680918b13654ddeccba53bdbbd72fb3a-IMG_1366.JPG' },
      { id: 4, url: '/images/4d49880d37bc33ca7bdfbff1eb6a7ec9-IMG_1367.JPG' },
    ],
    others: []
  },
  {
    id: 9,
    location: 'Lunda Sul',
    type: 'Arrendamento',
    title: 'Terreno Saurimo',
    hint: `Optima oportunidade de Negocio. Vasto terreno de 160.000 m<sup>2</sup>`,
    description: `Terreno com excelente localização, junto ao novo Hospital de Saurimo. Possibilidade de parcelar o terreno em duas ou mais partes. Uma das parcelas de terreno, possui tanque subterrâneo de combustivel para desnvolvimento do negócio de distribuição de combustíveis.`,
    cover: '111bd7c588691340516727a083bac114-cover.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/e48231ad43579dddd82051950356f1a2-foto1.jpg' },
      { id: 2, url: '/images/694b454b954c728de60478ee3b80260a-foto2.jpg' },
    ],
    others: []
  },
  {
    id: 10,
    location: 'Namibe',
    type: 'Arrendamento',
    title: 'Loja Namibe',
    hint: `Loja com 180 m<sup>2</sup> com boa localização no centro da cidade`,
    description: `Loja com mezanine situada na rua Eurico Gonçalves (junto à TAAG), excelente opção para instalar a sua loja, podendo também funcionar como escritório. Disponível para arrendamento.`,
    cover: '393a285d4b7962a2b922b79ad7353a86-cover.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/a3ba863231c27fd704890544ef8fedbc-IMG_1484.JPG' },
      { id: 2, url: '/images/00796284e0e7cb110d00a1a8111dcbe2-IMG_1488.JPG' },
    ],
    others: []
  },
  {
    id: 11,
    location: 'Huíla',
    type: 'Venda e arrendamento',
    title: 'Terreno Humpata',
    hint: `Terreno com 10.500 m<sup>2</sup> em Humpata`,
    description: `Terreno com 10.500 m<sup>2</sup> em Humpata`,
    cover: 'b9bedc46f35e1d13416dac924c19caf0-121.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/70cdd30a6aaa2eefd0412e30ce962478-IMG_1462.jpg' }
    ],
    others: []
  },
  {
    id: 12,
    location: 'Luanda',
    type: 'Venda E Arrendamento',
    title: 'Terreno Bom Jesus 1 – Junto Ao Futuro Aeroporto',
    hint: `Terreno de 22.500 m<sup>2</sup> situado perto da estrada de Catete`,
    description: `Terreno de 22.500 m<sup>2</sup> situado perto da estrada de Catete nas imediações do Novo Aeroporto Internacional.`,
    cover: 'd965a2ee0df8ac5891ba5209adc86cf8-IMG_1620.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/ed40356669333808b0c153b74aa8df5d-IMG_1620.jpg' }
    ],
    others: []
  },
  {
    id: 13,
    location: 'Luanda',
    type: 'Venda e Arrendamento',
    title: 'Terreno Bom Jesus 2 – Junto Ao Futuro Aeroporto',
    hint: `Terreno de 6.600 m<sup>2</sup> situado perto da estrada de Catete`,
    description: `Terreno de 6.600 m<sup>2</sup> situado perto da estrada de Catete nas imediações do Novo Aeroporto Internacional.`,
    cover: '7d4ce287c6b8d6fc66ed0cf65fef4ca0-IMG_1619.jpg',
    isValueMart: false,
    fotos: [
      { id: 1, url: '/images/38cdc441f6d91e11d4a04f57f6b4152a-IMG_1619.jpg' }
    ],
    others: []
  },
  {
    id: 14,
    location: 'Lubango',
    type: 'ARRENDAMENTO',
    title: 'Bairro Lalula',
    hint: `Terreno em Lubango destinado a comércio com mais de 3000 m<sup>2</sup>`,
    description: `Localizados em Lubango o terreno oferece mais de 3000 m<sup>2</sup> de área total e 1900 m<sup>2</sup> de área comercial`,
    cover: '4243724264866d267f73fee4e7e5f582-Picture5.jpg',
    isValueMart: true,
    fotos: [
      { id: 1, url: '/images/35f498b0a5e9703ad93e362f4bd68a2a-Picture5.jpg' },
      { id: 2, url: '/images/08c5f0667b39f9618bbd408c5f09cc4e-IMG_1422.JPG' },
    ],
    others: []
  },
  {
    id: 15,
    location: 'Cabinda',
    type: 'Arrendamento',
    title: 'Duque Chiaze',
    hint: `Terreno em Cabinda destinado a comércio com mais de 2100 m<sup>2</sup>`,
    description: `Localizados em Cabinda o terreno oferece mais de 2100 m<sup>2</sup> de área total e 960 m<sup>2</sup> de área comercial`,
    cover: 'd54134c4207c11754906d2ead2218e1c-Picture4.jpg',
    isValueMart: true,
    fotos: [
      { id: 1, url: '/images/1d7b250a94ffe07ec01f7f94b0cea690-Picture4.jpg' },
      { id: 2, url: '/images/5a8828c09ea7fc33a0c9846cb17098f4-IMG_1349.JPG' },
      { id: 3, url: '/images/3200dbf9644439a012ada158ecb42c53-IMG_1350.JPG' },
      { id: 4, url: '/images/4f94823a08b9032b927f20033814db70-IMG_1351.JPG' },
    ],
    others: []
  },
  {
    id: 16,
    location: 'Luanda',
    type: 'Arrendamento',
    title: 'Marimba',
    hint: `Terreno em Lauanda destinado a comércio com mais de 2500 m<sup>2</sup>`,
    description: `Localizados em Luanda o terreno oferece mais de 2500 m<sup>2</sup> de área total e 800 m<sup>2</sup> de área comercial`,
    cover: '15704f0b2680e9c7735b95f206447e59-Picture4.jpg',
    isValueMart: true,
    fotos: [
      { id: 1, url: '/images/5b699d82603c43ae2e9cdaeadc64c269-Picture1.jpg' },
      { id: 2, url: '/images/bd66866f8bc40e155265a98e5b153199-Picture2.jpg' },
      { id: 3, url: '/images/27518d2124c1113d1c9dc48c906c5489-Picture3.jpg' },
      { id: 4, url: '/images/624172aab72d817b66ebccbc1a0b1fc9-IMG_1571.JPG' },
      { id: 5, url: '/images/4aece814094018dd82fd814b9ef1d42b-IMG_1575.JPG' },
      { id: 6, url: '/images/65570dbaff65d242b542e46e2aa39561-IMG_1576.JPG' },
    ],
    others: []
  },
]