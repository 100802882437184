import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(90deg, #127ab6 50%, #014a73 50%);
  background: linear-gradient(#fff, #0b7bc0, #000);

  svg {
    z-index: 1;
    cursor: pointer;
    width: 80%;

    & > path {
      :hover {
        fill: #014a73;
      }

      z-index: 1;
    }
  }

  svg {
    padding: 0;
    margin: 0;
    align-self: center;
    align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 25px;

    text {
      z-index: 19;

      :hover {
        fill: #fff;
        color: #fff;
      }
    }
    @media only screen and (max-width: 768px) {
      max-height: 500px;
      max-width: 300px;
      margin-left: 50px;
    }
  }

  @media only screen and (min-width: 768px) {
    max-height: 800px;

    svg {
      width: 76% !important;
      padding-left: 80px;
    }
  }

  .info-hero {
    padding: 20px;

    @media only screen and (max-width: 768px) {
      background: linear-gradient(#fff, #127ab6, #000);
      color: #fff;

      .center-mobile {
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }

    h1 {
      font-size: 18px;
      text-transform: uppercase;

      @media only screen and (max-width: 768px) {
        font-size: 14px;
        text-align: center;
        color: #0b7bc0;
      }
    }
    h2 {
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        text-align: center;
        margin-top: 5px;
      }
    }

    p {
      @media only screen and (max-width: 768px) {
        padding: 5px 0;
        font-size: 14px;
        text-align: center;
        line-height: 1.6;
      }
    }

    a {
      @media only screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 30px;
        max-height: 500px;
        font-size: 14px;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    color: #fff;

    align-items: center;

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 24px;
    }

    .center-mobile {
      margin: 20px 0;

      a {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
`
