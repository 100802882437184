import React, { useState } from "react";
import { useEffect } from "react";
import { LayoutBase } from "../../components/LayoutBase";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import { ProjectItem } from "../../components/ProjectItem";


function ProjectsByProject() {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const history = useHistory();
  const location = `${history.location.pathname.split("/")[2]}`.split('%20').join()

  useEffect(() => {
    api.get('projects')
      .then(response => {
        setProjects(response.data.filter(project => project.location === location))
        setLoading(false);
      });
  }, [])


  return (
    <>
      {loading ? (
        <LayoutBase>
          <h1>Carregando ...</h1>
        </LayoutBase>
      ) : !loading && projects.length === 0 ? (
        <LayoutBase>
          <h1>Não existe imóveis</h1>
        </LayoutBase>
      ) : (
        <LayoutBase>
          <div className="projects">
            {projects.map(project => <ProjectItem key={project.id} project={project} />)}
          </div>
        </LayoutBase>
      )}
    </>
  );
}

export default ProjectsByProject;
