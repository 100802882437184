import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import slide1 from '../../assets/images/slide1.jpg'
import slide2 from '../../assets/images/slide2.jpg'
import slide3 from '../../assets/images/slide3.jpg'
import slide4 from '../../assets/images/slide4.jpg'
import slide5 from '../../assets/images/slide5.jpg'
import slide6 from '../../assets/images/slide6.jpg'
import slide7 from '../../assets/images/slide7.jpg'
import { Container } from './styles'

const Slides = () => {
  return (
    <Container id='about'>
      <Carousel infiniteLoop={true} autoPlay={true}>
        <div>
          <img src={slide1} alt='Slide 1' />
        </div>
        <div>
          <img src={slide2} alt='Slide 2' />
        </div>
        <div>
          <img src={slide3} alt='Slide 3' />
        </div>
        <div>
          <img src={slide4} alt='Slide 4' />
        </div>
        <div>
          <img src={slide5} alt='Slide 5' />
        </div>
        <div>
          <img src={slide6} alt='Slide 6' />
        </div>
        <div>
          <img src={slide7} alt='Slide 7' />
        </div>
      </Carousel>
    </Container>
  )
}

export default Slides
