import styled from "styled-components";

export const Container = styled.div`
  img {
    height: 400px;
  }

  .thumbs-wrapper.axis-vertical {
    display: none;
  }
`;
