import React from 'react'

import {
  Wrapper,
  Container,
  ContactInfo,
  HeaderInfo,
  ContactsNumbers,
  ContactNumber,
  Socials,
  SocialMedias,
  SocialItem,
  Email,
  EmailAddresses,
  Address,
  GeoLocation,
} from './styles'
import {
  FaCity,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaStreetView,
} from 'react-icons/fa'

function Contact() {
  return (
    <Wrapper id='page-info'>
      <Container>
        <ContactInfo>
          <ContactInfo>
            <HeaderInfo>
              <span>Endereço</span>
            </HeaderInfo>
            <ContactsNumbers>
              <ContactNumber>
                <FaStreetView /> Rua Gamal Abdel Nasser, Luanda
              </ContactNumber>
              <ContactNumber>
                <FaCity /> Edifício Tores Loanda, Piso 14, Fracção Nº 1
              </ContactNumber>
            </ContactsNumbers>
          </ContactInfo>
          <ContactInfo>
            <HeaderInfo>
              <span>Contactos</span>
            </HeaderInfo>
            <ContactsNumbers>
              <ContactNumber>
                <FaPhoneAlt /> +244 922 394 050
              </ContactNumber>
              <ContactNumber>
                <FaPhoneAlt /> +244 946 129 665
              </ContactNumber>
            </ContactsNumbers>
          </ContactInfo>

          <Socials>
            <HeaderInfo>
              <span>Redes Sociais</span>
            </HeaderInfo>
            <SocialMedias>
              <SocialItem>
                <FaFacebook />{' '}
                <a href='https://www.facebook.com/SkylandInvest'>Facebook</a>
              </SocialItem>
              <SocialItem>
                <FaInstagram />{' '}
                <a href='https://www.facebook.com/SkylandInvest'>Instagram</a>
              </SocialItem>
              <SocialItem>
                <FaLinkedin />{' '}
                <a href='https://www.facebook.com/SkylandInvest'>Linkedin</a>
              </SocialItem>
            </SocialMedias>
          </Socials>

          <Email>
            <HeaderInfo>
              <span>Email Marketing</span>
            </HeaderInfo>
            <EmailAddresses>
              <Address>
                <FaEnvelope />{' '}
                <a href='mailto:marketing@skylandinvest.com'>
                  marketing@skylandinvest.com
                </a>
              </Address>
            </EmailAddresses>
          </Email>
        </ContactInfo>
        <GeoLocation>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.7093743316673!2d13.237572214777675!3d-8.813349893670573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f2490d118ff1%3A0xb11dc19017724146!2sTorres%20LOANDA!5e0!3m2!1spt-PT!2sao!4v1605816655356!5m2!1spt-PT!2sao'
            title='Mapa'
            frameBorder='0'
            allowFullScreen=''
            aria-hidden='false'
            tabIndex='0'
            width='100%'
            height='100%'
          ></iframe>
        </GeoLocation>
      </Container>
    </Wrapper>
  )
}

export default Contact
