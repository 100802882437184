import styled from 'styled-components';

export const Container = styled.div`
  background: #FFF;
  padding: 20px;
  min-height: 755.1px;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    justify-content: space-between;
    align-items: center;
    padding: 20px;

    button {
      border: 0;
      background: rgb(51, 51, 51);
      padding: 10px 20px;
      color: #fff;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }


    h2 {
      text-transform: uppercase;
      line-height: 1.7;
      font-size: 1.17em;
    }

    p {
      font-size: 16px;
    }

    padding-bottom: 10px;

    border-bottom: 1px solid rgb(238, 238, 238);
  }

  .fotos {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    .foto {
      border: 1px solid rgb(238, 238, 238);
      height: 146px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .iframe {
    display: flex;
    grid-template-columns: 1fr;

    * {
      width: 100%;
      margin-top: 20px;
      height: 420px !important;
    }

    padding-bottom: 50px;
  }

  .columns {
    height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 20px;

      p {
        font-size: 14px;
        border-top: 1px solid rgb(238, 238, 238);
        border-bottom: 1px solid rgb(238, 238, 238);
        padding-bottom: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        margin-top: 10px;
      }

      h3 {
        text-transform: uppercase;
        line-height: 1.7;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        
        &:last-child {
          border-top: 1px solid rgb(238, 238, 238);
          padding-top: 20px;
          margin-top: 20px;
        }
      }
    }
  }

`