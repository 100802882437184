import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #000;
  height: 800px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`
export const Container = styled.div`
  color: #fff;
  width: 1148px;

  @media only screen and (min-width: 768px) {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`
export const InfoManagment = styled.div`
  padding: 20px;

  h1 {
    text-transform: uppercase;
    font-size: 18px;
    color: #0b7bc0;
  }

  h2 {
    padding: 10px 0 20px 0;
  }

  h3 {
    margin-bottom: 14px;
    color: #0b7bc0;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }

  p::after {
    content: '';
    display: block;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 6px;
      font-size: 14px;

      span {
        color: #0b7bc0;
      }
    }
  }

  @media only screen and (max-width: 786px) {
    h1 {
      text-transform: uppercase;
      font-size: 14px;
      color: #0b7bc0;
      text-align: center;
    }

    h2 {
      padding: 10px 0 20px 0;
      text-align: center;
    }

    p {
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }

    h2,
    h3,
    ul {
      text-align: center;
    }

    ul span {
      display: none;
    }

    ul li {
      /* text-transform: uppercase; */
      padding: 5px;
      background: #fff;
      color: #000;
      border-radius: 20px;
      transition: transform 0.2s;
    }
    ul li:last-child {
      margin-bottom: 20px;
    }

    ul li:hover {
      transform: scale(1.021);
    }
  }
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    width: 80%;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 10px;

  img {
    width: 100%;
  }

  img:not(:last-child) {
    margin-bottom: 10px;
  }
`
