import React from "react";
import GlobalStyles from "./styles/GlobalStyles";
import { BrowserRouter as Routes, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Projects from "./pages/Projects";
import SingleProject from "./pages/Projects/SingleProject";
import ProjectsByProject from "./pages/ProjectsByProject";
import ProjectsValueMart from "./pages/ProjectsValueMart";
import News from "./pages/News";
import SingleNew1 from "./pages/News/SingleNew1";
import SingleNew2 from "./pages/News/SingleNew2";
import SingleNew3 from "./pages/News/SingleNew3";
import SingleNew4 from "./pages/News/SingleNew4";
import SingleNew5 from "./pages/News/SingleNew5";
import SingleNew6 from "./pages/News/SingleNew3 copy";

function App() {
  return (
    <>
      <Routes>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projects/:id" component={SingleProject} />
          <Route exact path="/news" component={News} />
          <Route exact path="/news/1" component={SingleNew1} />
          <Route exact path="/news/2" component={SingleNew2} />
          <Route exact path="/news/3" component={SingleNew3} />
          <Route exact path="/news/4" component={SingleNew4} />
          <Route exact path="/news/5" component={SingleNew5} />
          <Route exact path="/news/6" component={SingleNew6} />
          <Route
            exact
            path="/provinces/:name/projects"
            component={ProjectsByProject}
          />
          <Route
            exact
            path="/value-mart/projects"
            component={ProjectsValueMart}
          />
        </Switch>
      </Routes>
      <GlobalStyles />
    </>
  );
}

export default App;
