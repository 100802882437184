import styled from "styled-components";

export const Container = styled.div`
  max-width: 1148px;
  width: 100%;
  margin: 0 auto;

  .info-line {
    margin: 20px 0;
    font-size: 14px;

    border-top: 1px solid #ccc;
    padding: 10px 3px;

    h3 {
      padding: 10px 0;
      font-size: 18.72px;
    }

    .phones {
      display: flex;
      flex-direction: column;
    }

    .phone {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 5px;
      }
    }
    .email-info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 5px;

      svg {
        margin-right: 5px;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back {
      a {
        display: flex;
        align-items: center;
        width: 65px;
        background-color: #1487c9;
        padding: 10px 15px;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        border-radius: 20px;
        text-decoration: none;
        color: #fff;
      }

      span.goBack {
        display: flex;
        height: 20px;
        align-items: center;
        width: 65px;
        background-color: #1487c9;
        padding: 10px 15px;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        border-radius: 20px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
      }
    }

    span {
      height: 70px;
      display: flex;
      align-items: center;

      border-bottom: 1px solid #1487c9;
    }

    @media screen and (max-width: 768px) {
      span {
        margin-right: 5px;
      }
    }
  }

  .projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    .project {
      background-color: #f5f5f5;
      padding: 10px;
      display: flex;
      flex-direction: column;
      min-height: 250px;

      img {
        width: 100%;
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;

        strong {
          text-transform: uppercase;
        }
      }

      button {
        width: 146.98px;
        border: none;
        padding: 10px 15px;
        align-self: center;
        background-color: #1487c9;
        color: #fff;
        border-radius: 20px;
        font: 16px "Encode Sans Expanded";
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .content-item {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;

    .detail-item {
      background: #fff;
      padding: 25px;
      /* border-radius: 20px; */
      margin-right: 15px;

      min-height: 150px;

      .map-region {
        width: 100%;

        .leaflet-container {
          height: 300px;
        }

        .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
          z-index: 1;
        }
      }

      h3 {
        text-transform: uppercase;
        line-height: 1.7;
      }

      .line {
        border: 1px solid #eee;
        margin: 15px 0;
      }
    }

    .gallery-item {
      background: #fff;
      flex: 1;
      padding: 25px;
      /* border-radius: 20px; */
      margin-right: 15px;

      h3 {
        text-transform: uppercase;
        line-height: 1.7;
      }

      .line {
        border: 1px solid #eee;
        margin: 15px 0;
      }

      .photos-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding-top: 10px;
        grid-gap: 10px;

        img {
          width: 100%;
          border: 3px solid #ccc;
          max-height: 140px;
        }
      }
    }

    .photos img {
      width: 100%;
    }

    .details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;

      .box-details {
        background: #fff;
        padding: 15px;

        h2 {
          margin-top: 13px;
          text-transform: uppercase;
          line-height: 1.7;
          font-family: "Encode Sans Expanded", sans-serif;
          font-size: 18.72px;
        }

        p {
          font-family: "Encode Sans Expanded", sans-serif;
          font-size: 14px;
        }

        .line {
          border: 1px solid #eee;
          margin: 15px 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .line {
      display: none;
    }
    .content-item {
      flex-direction: column;
      width: 100%;
      width: 451px;
      margin: 0 auto;

      .detail-item {
        margin-bottom: 20px;
        border-radius: 1px;
        width: 100%;
        max-width: 401px;

        h3 {
          font-size: 14px;
          text-align: center;
        }

        .desc {
          margin-top: 10px;
        }

        p {
          font-size: 12px;
          text-align: center;
        }
      }
      .gallery-item {
        margin-bottom: 20px;
        border-radius: 1px;
        width: 100%;
        max-width: 401px;

        h3 {
          font-size: 14px;
          text-align: center;
        }

        .desc {
          margin-top: 10px;
        }

        p {
          font-size: 12px;
          text-align: center;
        }

        .photos-gallery {
          grid-template-columns: 1fr;

          img {
            width: 100%;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .content-item .detail-item {
    width: 250px !important;
    height: 100% !important;

    .saiba-mais {
      background: #333;
      padding: 10px 20px;
      display: inline-block;
      margin-top: 10px;
      color: #fff;
      border-radius: 20px;
      text-decoration: none;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 1.8;
    }
  }
`;
export const Main = styled.main`
  background: #333;
  background: linear-gradient(#0b7bc0, #000);
  .cover {
    img {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }
`;
export const LogoWrapper = styled.div`
  img {
    width: 80px;
    height: 80px;
  }
`;

export const Content = styled.div`
  max-width: 1148px;
  width: 100%;
  margin: 0 auto;

  display: grid;

  grid-template-columns: 295px 1fr;

  gap: 10px;

  padding-top: 30px;
  padding-bottom: 30px;

 
`;


export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  padding: 38px 0;
  font-weight: bold;
  font-size: 20px;
`