import React, { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { LayoutBase } from "../../components/LayoutBase";
import api from "../../services/api";

import { Container, Main } from "./styles";


export function ProjectItem({ project }) {

  return (
    <div className="project" key={project.id}>
      <img
        src={`/images/${project.cover}`}
        alt={project.title}
      />
      <div className="detail">
        <span className="province">{project.location}</span>
        <span
          style={{
            padding: 0,
            margin: 0,
            fontSize: "10px",
            marginBottom: "10px",
            textTransform: "uppercase",
            fontWeight: "600",
            color: "#097ABE",
          }}
        >
          {project.type}
        </span>
        <strong>{project.title}</strong>
        <p
          dangerouslySetInnerHTML={{ __html: project.hint }}
        ></p>
      </div>
      <Link className="button" to={`/projects/${project.id}`}>
        Saiba mais
      </Link>
    </div>
  )
}