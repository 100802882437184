import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import pic1 from "../../../assets/images/torre-ambient.jpg";
import post1 from "../../../assets/images/post-1.pdf";

import { Container, Main, Post } from "./styles";

function SingleNew1() {
  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              <Link to="/news">
                <FaArrowLeft /> Voltar
              </Link>
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>
                <header>
                  <img src={pic1} alt="Post 1" className="h-7" />
                </header>
                <section>
                  <h2>MERCADO IMOBILIÁRIO CAI MAIS DE 40% ESTE ANO.</h2>
                  <p>
                    Crise: Pandemia colocou o sector imobilário em coma
                    profundo, considera a Associação dos Profissionais
                    Imobiliários de Angola. Procura caiu drasticamente. Há
                    empresas paralisadas.
                  </p>

                  <p>
                    <strong>Fonte: Valor Económico</strong>
                  </p>

                  <br />

                  <a href={post1} target="_blank" rel="noopener noreferrer">
                    Ver notícia completa
                  </a>
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default SingleNew1;
