import styled from 'styled-components'
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? '#1487C9' : '#010606')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }) => (dark ? '#010606' : '#fff')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  opacity: ${({ buttonHide }) => (buttonHide ? '0' : '1')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? '#fff' : '#1487C9')};
  }
`

export const Button2 = styled(LinkR)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? '#fff' : '#092F48')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }) => (dark ? '#010606' : '#fff')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  opacity: ${({ buttonHide }) => (buttonHide ? '0' : '1')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: 1px solid #fff;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #000;
    background: ${({ primary }) => (primary ? '#1487C9' : '#fff')};
    border: 1px solid #000;
  }
`
