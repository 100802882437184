export const maps = [
  {
    id: "5fa468347c948f4df0bcd7bc",
    name: "Complexo InterMarket Luanda",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.2519991572735!2d13.23978901477034!3d-8.806027593675722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f2477427d32d%3A0x680466afa6692a10!2sIntermarket.!5e1!3m2!1spt-PT!2sao!4v1610279775286!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5a9e5f400cb09f2a8e3d9",
    name: "Cabassango",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d987.5931325722597!2d12.233786129192696!3d-5.558833299748089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNcKwMzMnMzEuOCJTIDEywrAxNCcwMy42IkU!5e1!3m2!1spt-PT!2sao!4v1611166474409!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb59c914885480540f71170",
    name: "Complexo Industrial Cimianto",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.7963509264932!2d13.316951327650617!3d-8.779533793976249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f177ca13ef29%3A0x392d93d17c4f980b!2sCimianto!5e1!3m2!1spt-PT!2sao!4v1610277671589!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5a3a14885480540f71171",
    name: "Escritório Lubango",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d958.8135131823446!2d13.492813929198496!3d-14.918472199350035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTTCsDU1JzA2LjUiUyAxM8KwMjknMzYuMSJF!5e1!3m2!1spt-PT!2sao!4v1610303772402!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5a719f400cb09f2a8e3d4",
    name: "11 de Novembro/ SAPU",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.796326803693!2d13.297971026057915!3d-8.934472193868219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwNTYnMDQuMSJTIDEzwrAxOCcwMC40IkU!5e1!3m2!1spt-PT!2sao!4v1610278241732!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5a9a1f400cb09f2a8e3d8",
    name: "ARMAZÉM LUVASSA",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3884.351250771572!2d12.173458884725601!3d-5.588527796082265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNcKwMzUnMTguNyJTIDEywrAxMCczMi4yIkU!5e1!3m2!1spt-PT!2sao!4v1610278374878!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb6de5c9e821f181f0344c4",
    name: "TERRENO BOM JESUS 1 – JUNTO AO FUTURO AEROPORTO",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3853.868646716523!2d13.526237220405541!3d-9.002138893756092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOcKwMDAnMDcuNyJTIDEzwrAzMSc0Mi4yIkU!5e1!3m2!1spt-PT!2sao!4v1610278981804!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb6ded29e821f181f0344c5",
    name: "TERRENO BOM JESUS 2 – JUNTO AO FUTURO AEROPORTO",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3853.8686381252514!2d13.5261903!3d-9.0021397!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOcKwMDAnMTEuMCJTIDEzwrAzMSczNi41IkU!5e1!3m2!1spt-PT!2sao!4v1610279037073!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb6e29f9e821f181f0344c8",
    name: "Edifício one Metropolis",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26173.390388560198!2d13.180552454616109!3d-8.92658317646574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26193b1c5a92291e!2sEdif%C3%ADcio%20Valor%20Metropolis!5e1!3m2!1spt-PT!2sao!4v1610277064048!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb6e37a9e821f181f0344c9",
    name: "Complexo Industrial Panga Panga",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1961.0767740011104!2d13.264163358053654!3d-8.815306248417327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f22a6b584675%3A0xaaaea8a1759cc607!2sPanga%20Panga%2C%20Luanda!5e1!3m2!1spt-PT!2sao!4v1610303869529!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fce9348b02d067966f297dd",
    name: "BAIRRO LALULA",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.130213809391!2d13.506403592137747!3d-14.910138889951439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTTCsDU0JzM2LjUiUyAxM8KwMzAnMzAuOCJF!5e1!3m2!1spt-PT!2sao!4v1610279429984!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fce93f0b02d067966f297de",
    name: "Duque Chiaze",
    html: null,
    img: '/images/maps/duque.JPG',
  },
  {
    id: "5fce94d9b02d067966f297df",
    name: "MARIMBA",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1753.0102122912192!2d13.312522850975963!3d-8.783053998740032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwNDYnNTkuMCJTIDEzwrAxOCc0OC42IkU!5e1!3m2!1spt-PT!2sao!4v1610299728088!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5b517f400cb09f2a8e3df",
    name: "Terreno Humpata",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3747.1567664382983!2d13.324276770109245!3d-15.070444389966958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTXCsDA0JzEzLjYiUyAxM8KwMTknMzUuMSJF!5e1!3m2!1spt-PT!2sao!4v1611166598984!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5aad1f400cb09f2a8e3db",
    name: "Loja Namibe",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1881.2104628054792!2d12.14745283983946!3d-15.19608329744916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTXCsDExJzQ1LjkiUyAxMsKwMDgnNTQuNyJF!5e1!3m2!1spt-PT!2sao!4v1610278733256!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
  {
    id: "5fb5aa6ef400cb09f2a8e3da",
    name: "Terreno Saurimo",
    img: null,
    html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3845.1592905320754!2d20.350293481134557!3d-9.667472193316394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOcKwNDAnMDIuOSJTIDIwwrAyMScwOC44IkU!5e1!3m2!1spt-PT!2sao!4v1610278581236!5m2!1spt-PT!2sao" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  },
]