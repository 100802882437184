import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createServer } from 'miragejs'
import { projects } from './data/projects';

createServer({
  routes() {
    this.namespace = 'api';

    this.get('/projects/value-mart', () => {

      return projects
    })

    this.get('/projects', () => {
      return projects
    })
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
