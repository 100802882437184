import styled from 'styled-components';

export const Container = styled.div`

  background: #FFF;
  padding: 20px;

  h2, h3 {
    text-transform: uppercase;
    line-height: 1.7;
    padding-top: 13px;
  }

  h2 {
    font-size: 1.17em;
  }

  h3 {
    font-size: 14px;
  }

  .info {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(238, 238, 238);

    p {
      font-size: 14px;
      line-height: 1.7;
    }
  }


  ul {
    list-style-type: none;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 10px;
      }

      svg {
        font-size:18px;
      }

      span, a {
        font-size: 14px;
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

`