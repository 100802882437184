import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import pic1 from "../../assets/images/torre-ambient.jpg";
import pic2 from "../../assets/images/news/post2.jpg";
import pic4 from "../../assets/images/bna.jpeg";
import post1 from "../../assets/images/post-1.pdf";
import premioImg from "../../assets/images/premio-2022.jpg";
import premioImg2 from "../../assets/images/news/post2-1.JPG";
import galardaoImg from "../../assets/images/galardao.jpg";

import { Container, Main, Post } from "./styles";

function News() {
  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              <Link to="/#news">
                <FaArrowLeft /> Voltar
              </Link>
            </div>

            <span>Notícias</span>
          </header>
        </Container>
        <Main>
          <Container>
            <div class="posts">
              <Post>
                <header>
                  <img src={premioImg} alt="Post 1" />
                </header>
                <section>
                  <h2>MELHOR COLABORADORA DO ANO 2021.</h2>
                  <p>
                    Valorizamos a excelência na industria imobiliária, portanto
                    não poderiamos deixar de reconhecer aqueles que fazem o
                    nosso trabalho possível com empenho e dedicação.
                  </p>
                  <Link to="/news/3?list=true" rel="noopener noreferrer">
                    Saiba Mais
                  </Link>
                </section>
              </Post>
              <br />
              <Post>
                <header>
                  <img src={galardaoImg} alt="Post 1" />
                </header>
                <section>
                  <h2>GALARDÃO DE EXCELENCIS SKYLAND</h2>
                  <p>
                    Valorizamos a excelência na industria imobiliária, portanto
                    não poderiamos deixar de reconhecer aqueles que fazem o
                    nosso trabalho possível com empenho e dedicação.
                  </p>
                  <Link to="/news/5?list=true" rel="noopener noreferrer">
                    Saiba Mais
                  </Link>
                </section>
              </Post>
              <br />
              <Post>
                <header>
                  <img src={premioImg2} alt="Post 6" />
                </header>
                <section>
                  <h2>MELHOR COLABORADOR DO ANO 2020.</h2>
                  <p>
                    Valorizamos a excelência na industria imobiliária, portanto
                    não poderiamos deixar de reconhecer aqueles que fazem o
                    nosso trabalho possível com empenho e dedicação.
                  </p>
                  <Link to="/news/6?list=true" rel="noopener noreferrer">
                    Saiba Mais
                  </Link>
                </section>
              </Post>
              <br />
              <Post>
                <header>
                  <img src={pic1} alt="Post 1" />
                </header>
                <section>
                  <h2>MERCADO IMOBILIÁRIO CAI MAIS DE 40% ESTE ANO.</h2>
                  <p>
                    Crise: Pandemia colocou o sector imobilário em coma
                    profundo, considera a Associação dos Profissionais
                    Imobiliários de Angola. Procura caiu drasticamente. Há
                    empresas paralisadas.
                  </p>
                  <a href={post1} rel="noopener noreferrer">
                    Saiba Mais
                  </a>
                </section>
              </Post>
              <br />
              <Post>
                <header>
                  <img src={pic2} alt="Post 1" className="h-7" />
                </header>
                <section>
                  <h2>RELÁTORIO IMOBILIÁRIO 2020.</h2>
                  <p>
                    Relatório criado pela Zenki Real Estate com finalidade de
                    mostrar a realidade o mercado imobiliário angolano em 2020.
                    Descarregue o documento em PDF com todo conteúdo.
                  </p>

                  <Link to={"/news/2?list=true"} rel="noopener noreferrer">
                    Saiba Mais
                  </Link>
                </section>
              </Post>

              <br />
              <Post>
                <header>
                  <img src="https://www.ft.com/__assets/creatives/product/dynamic-barriers/markets.jpg" alt="Post 1" />
                </header>
                <section>
                  <h2>FINANCIAL TIMES</h2>
                  <p>
                    Valorizamos a excelência na industria imobiliária, portanto
                    não poderiamos deixar de reconhecer aqueles que fazem o
                    nosso trabalho possível com empenho e dedicação.
                  </p>
                  <a href="https://www.ft.com/content/4652e15a-f7ba-4d21-9788-41db251c5a76" target="_blank">
                    Saiba Mais
                  </a>
                </section>
              </Post>
              <br />
              <Post>
                <header>
                  <img src={pic4} alt="Post 1" />
                </header>
                <section>
                  <h2>
                    INVESTIDORES ESTRAGEIROS PODEM REPATRIAR CAPITAIS SEM
                    AUTORIZAÇAO DO BNA
                  </h2>
                  <p>
                    Os empresários estrangeiros que decidem investir em Angola
                    têm agora a vida mais facilitada, com a entrada em vigor de
                    uma medida que permite que os estrangeiros invistam em
                    Angola...
                  </p>
                  <a
                    href="https://correiokianda.info/investidores-estrangeiros-podem-repatriar-seus-capitais-sem-autorizacao-do-bna/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Saiba Mais
                  </a>
                </section>
              </Post>
            </div>
          </Container>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export default News;
