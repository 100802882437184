import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(#fff, #0b7bc0, #000);
  height: 800px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`;

export const Container = styled.div`
  color: #fff;
  width: 1148px;

  .btn-desktop {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .btn-center {
      justify-content: flex-start;
      align-items: flex-start;

      a {
        text-align: left;
        background: red;
      }
    }
  }
`;
export const InfoManagment = styled.div`
  padding: 20px;

  h1 {
    text-transform: uppercase;
    font-size: 18px;
    color: #0b7bc0;
  }

  @media only screen and (max-width: 768px) {
    h1,
    h2,
    p {
      color: #000;
    }
  }

  @media only screen and (min-width: 768px) {
    h1.white {
      color: #fff !important;
    }
  }

  h2 {
    padding: 10px 0 20px 0;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }

  .btn-center {
    display: flex;
    align-items: center;

    a {
      text-align: center;
      text-decoration: none;
      color: #fff;
      background: #000;
      padding: 10px 25px;
      border-radius: 20px;
      margin-top: 10px;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (min-width: 768px) {
    .btn-center {
      display: none;
    }

    .btn-desktop {
      display: block;
      margin-top: 20px;

      a {
        text-align: center;
        text-decoration: none;
        color: #fff;
        background: #000;
        padding: 10px 25px;
        border-radius: 20px;
      }
    }
  }

  @media only screen and (max-width: 786px) {
    h1 {
      text-transform: uppercase;
      font-size: 14px;
      color: #0b7bc0;
      text-align: center;
    }

    h2 {
      padding: 10px 0 20px 0;
      text-align: center;
    }

    p {
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }
  }
`;
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* img {
    width: 80%;
  } */

  .news {
    background-color: rgba(255, 255, 255, 0.3);
    height: 800px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 5px;
    padding: 5px 5px;

    .card {
      background-color: #333;
      display: flex;
      height: auto;
      width: auto;
      flex-direction: column;
      align-items: center;

      .card-cover {
        img {
          width: 100%;
          height: 405px;
          object-fit: cover;
        }
      }

      .card-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
      }

      .card-info h1 {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .card-info h2 {
        font-size: 15px;
        font-weight: 300;
      }

      .card-info .btn {
        display: inline-block;
        padding: 10px 20px;
        width: 80px;
        text-align: center;
        background: #119bd9;

        margin-top: 25px;
        border-radius: 20px;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
      }

      .card-info .btn:hover {
        color: #000;
        background: #fff;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .news {
      height: 2000px !important;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
