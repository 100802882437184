import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import Footer from "../Footer";
import { Link as LinkR, useHistory } from "react-router-dom";
import {
  Container,
  Main,
  LogoWrapper,
  Center,
  Content
} from "./styles";

import logo from "../../assets/images/logo1.png";
import { LeftItem } from "../LeftItem";
import { RightItem } from "../RightItem";

export function LayoutSingle({ children, project, isLoading }) {

  const history = useHistory();

  return (
    <>
      <div id="projects">
        <Container>
          <header>
            <div className="back">
              <span
                className="goBack"
                onClick={() => {
                  history.goBack();
                }}
              >
                <FaArrowLeft /> Voltar
              </span>
            </div>

            <LogoWrapper>
              <LinkR to="/">
                <img src={logo} alt="Logo" />
              </LinkR>
            </LogoWrapper>

            <span>{project.title}</span>
          </header>
        </Container>
        <Main>
          {isLoading ? (
            <Center>
              Carregando...
            </Center>
          ) : (
            <>
              <div className="cover">
                <img
                  src={`/images/${project.cover}`}
                  alt={project.title}
                />
              </div>
              <Content>
                <LeftItem {...project} />
                <RightItem {...project} />
              </Content>
            </>
          )}
        </Main>
      </div >
      <Footer />
    </>
  )
}