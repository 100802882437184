import React, { useState } from 'react';
import { maps } from '../../data/maps';
import { Container } from "./styles";
import { FaArrowLeft } from "react-icons/fa";



export function RightItem({ title, type, description, fotos, others }) {

  const [toggle, setToggle] = useState(false);
  const iframe = maps.find(m => m.name.toLowerCase() === title.toLowerCase())


  return <Container>
    <header>
      {toggle ? (
        <button onClick={() => setToggle(false)}>
          <FaArrowLeft /> Voltar
        </button>
      ) : (
        <div>
          <h2>Galeria de fotos</h2>
          <p>Desfrute do conjunto de fotos</p>
        </div>
      )}
      {!toggle && others.length > 0 ? (
        <div>
          <button onClick={() => setToggle(true)}>Saiba mais</button>
        </div>
      ) : null}
    </header>
    {!toggle ? (
      <>
        <div className="fotos">
          {fotos?.map(foto => (
            <div className="foto" key={foto.id}>
              <a href={foto.url} target="_blank">
                <img src={foto.url} alt={`Foto ${foto.id}`} />
              </a>
            </div>
          ))}
        </div>

        <div className="iframe">
          {iframe && iframe.html ? (
            <div
              dangerouslySetInnerHTML={{ __html: iframe.html }}
            ></div>
          ) : iframe && iframe.img ? (
            <img src={iframe.img} alt="Mapa" />
          ) : null}

        </div>
      </>
    ) : (
      <div className="columns">
        {others?.map(o => (
          <div key={o.id} className="column">
            <h3>{o.title}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: o.description }}
            ></p>
            {o.fotos?.map(f => (
              <img src={f.url} key={f.id} />
            ))}
          </div>
        ))}
      </div>
    )}
  </Container>
}