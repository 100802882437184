import React from "react";
import { FaPhoneAlt } from "react-icons/fa";

import { Container } from "./styles";

const OportunityBusiness = () => {
  return (
    <Container>
      <h1>Contactos comerciais</h1>

      <div className="contacts">
        <div className="contact">
          <FaPhoneAlt />
          <span>+244 922 394 050</span>
        </div>
        <div className="contact">
          <FaPhoneAlt />
          <span>+244 946 129 665</span>
        </div>
      </div>
    </Container>
  );
};

export default OportunityBusiness;
