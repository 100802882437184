import React from 'react';
import { Container } from "./styles";
import { FaEnvelope, FaGlobe, FaPhoneAlt } from "react-icons/fa";

export function LeftItem({ title, type, description }) {
  return <Container>
    <div className="info">
      <h2>Imóvel</h2>
      <p>{title}</p>
    </div>
    <div className="info">
      <h3>Objectivo do Negócio</h3>
      <p>{type}</p>
    </div>
    <div className="info">
      <h2>Descrição</h2>
      <p
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
    <div className="contacts">
      <h2>Contactos</h2>
      <ul>
        <li><FaPhoneAlt /> <span>+244 922 394 050</span></li>
        <li><FaPhoneAlt /><span>+244 946 129 665</span></li>
        <li><FaEnvelope /> <span>marketing@skylandinvest.com</span></li>  
        {title === 'Edifício one Metropolis' ? <li><FaGlobe /> <a href='https://onemetropolis.ao' target="_blank">https://www.onemetropolis.ao</a></li> : null}
      </ul>
    </div>
  </Container>
}